<template>
  <div class="flex items-center justify-center">
    <div class="w-full">
      <div class="text-center">
        <span
          class="inline-block rounded-full border px-4 py-3"
          :class="{
            'bg-slate-500': !verify,
            'bg-slate-200': verify
          }"
        >
          <i
            class="fa-solid fa-lock text-4xl"
            :class="{
              'text-gray-300': !verify,
              'text-green-600': verify
            }"
          />
        </span>
      </div>
      <div class="text-center mt-10">
        <button
          type="button"
          class="btn w-full"
          :href="lineLoginUrl"
          :disabled="!verify"
          @click="send"
        >
          ログイン
        </button>
      </div>
      <div class="text-sm bg-gray-100 p-6 mt-6">
        <div v-if="!errorMessage">
          LINEアカウントでログインします
        </div>
      </div>
    </div>
  </div>
</template>
<script lang="ts" setup>

const indexDb = useIndexDB()
const token = computed(() => {
  return useRoute().query.token as string
})

const pid = computed(() => {
  return useRoute().query.pid as string
})
const setting = ref<AdminSetting>()
const loginApiUrl = new URL('https://access.line.me/oauth2/v2.1/authorize')
const errorMessage = ref('')
const csrf = ref('')

const lineLoginUrl = computed(() => {
  if (!setting.value) {
    return ''
  }
  const u = new URLSearchParams({
    response_type: 'code',
    client_id: setting.value.lineClientId,
    redirect_uri: setting.value.lineRedirectUri,
    state: csrf.value,
    scope: 'profile openid email'
  })
  loginApiUrl.search = u.toString()

  return loginApiUrl.toString()
})

function send () {
  window.location.href = lineLoginUrl.value
}

const verify = computed(() => {
  return !!csrf.value && !!token.value && !!pid.value && !!setting.value?.token
})

onMounted(async () => {
  if (!token.value || !pid.value) {
    return
  }

  setting.value = await useAdminSetting(token.value)
  if (!setting.value) {
    return
  }

  csrf.value = new Date().getTime().toString(16) + Math.floor(1000 * Math.random()).toString(16)

  indexDb.setItem(token.value, {
    token: token.value,
    pid: pid.value,
    csrf: csrf.value,
    isLineLogin: setting.value.isLineLogin,
    logOutUrl: setting.value.logOutUrl || '/logout'
  })

  const date = new Date()
  date.setHours(date.getHours() + 1)

  useCookie('luser', {
    expires: date
  }).value = token.value

  if (setting.value.isLineLogin === 'on') {
    try {
      const line = await indexDb.getItem(token.value)
      if (line?.access_token) {
        // ログイン済チェック
        const expiresIn = await useVerifyToken(line?.access_token)
        if (expiresIn) {
          return navigateTo({
            path: '/editor'
          })
        }
      }
    } catch (e) {
      console.error('e:', e)
    }

    return
  }

  return navigateTo({
    path: '/editor',
    query: {
      csr: csrf.value
    }
  })
})
</script>
<style lang="scss" scoped>
</style>
